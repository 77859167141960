import React, { useEffect, useState } from 'react';
import styles from './css/OurWork.module.css';

const OurWork = () => {
    const [projects, setProjects] = useState([]);
    const [activeIndex, setActiveIndex] = useState(0); // Індекс активного проекту
    const [isHovered, setIsHovered] = useState(false); // Чи наведено на назву проекту

    useEffect(() => {
        // Завантаження проектів з API
        fetch(`${process.env.REACT_APP_API_URL}/api/projects`)
            .then((res) => res.json())
            .then((data) => setProjects(data))
            .catch((err) => console.error(err));
    }, []);

    useEffect(() => {
        if (!isHovered && projects.length > 0) {
            // Автоматична зміна проекту кожні 3 секунди
            const interval = setInterval(() => {
                setActiveIndex((prevIndex) => (prevIndex + 1) % projects.length);
            }, 3000);

            return () => clearInterval(interval); // Очищення інтервалу
        }
    }, [isHovered, projects]);

    const handleMouseEnter = (index) => {
        setIsHovered(true);
        setActiveIndex(index); // Встановлюємо активний проект, коли наведено на назву
    };

    const handleMouseLeave = () => {
        setIsHovered(false); // Вимикаємо режим наведення
    };

    return (
        <div id = "work" className={styles.ourworkcontainer}>
            <h2 className={styles.ourworktitle}>Our Work</h2>

            {/* Дві колонки */}
            <div className={styles.ourworkcolumns}>
                {/* Ліва колонка (список проектів) */}
                <ul className={styles.projectlist}>
                    {projects.map((project, index) => (
                        <li
                            key={project._id}
                            className={`${styles.projectitem} ${index === activeIndex ? styles.active : ''}`}
                            onMouseEnter={() => handleMouseEnter(index)} // Обробник наведення
                            onMouseLeave={handleMouseLeave} // Обробник покидання
                        >
                            <a
                                href={project.link}
                                target="_blank"
                                rel="noopener noreferrer"
                                className={styles.projectlink}
                            >
                                {project.name}
                                <img src="/images/arrow.png" alt="arrow"/>
                            </a>

                        </li>
                    ))}
                </ul>

                {/* Права колонка (зображення проекту) */}
                <div className={styles.imagecontainer}>
                    {projects.length > 0 ? (
                        <img
                            className={styles.projectimage}
                            src={`${process.env.REACT_APP_API_URL}${projects[activeIndex].imageUrl}`}
                            alt={projects[activeIndex].name}
                        />
                    ) : (
                        <p>Завантаження проектів...</p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default OurWork;
