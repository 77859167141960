import React, { useState, useRef, useEffect } from 'react';
import styles from './css/FullScreenSections.module.css';
import Header from './Header';
import ProfessionSection from './ProfessionSection';
import AboutUs from './AboutUs';
import OurWork from './OurWork';
import OurServices from './OurServices';
import FormSection from './FormSection';

const FullScreenSections = () => {
    const sections = [
        { component: ProfessionSection, id: 'profession' },
        { component: AboutUs, id: 'about' },
        { component: OurWork, id: 'work' },
        { component: OurServices, id: 'services' },
    ];

    const [currentSection, setCurrentSection] = useState(0); // Initial section
    const [isFormActive, setIsFormActive] = useState(false); // Track if form is active
    const isScrolling = useRef(false);
    const touchStartY = useRef(0);
    const touchEndY = useRef(0);

    useEffect(() => {
        // Disable global scrolling for the body
        document.body.style.overflow = 'hidden';
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, []);

    const toggleForm = (isOpen) => {
        setIsFormActive(isOpen);
    };

    const scrollToSection = (targetIndex) => {
        if (isScrolling.current || targetIndex === currentSection) return;

        isScrolling.current = true;
        setTimeout(() => {
            isScrolling.current = false;
        }, 800); // Block scrolling for 800ms

        setCurrentSection(targetIndex);
    };

    const handleScroll = (event) => {
        if (isScrolling.current || isFormActive) return;

        const nextSection = currentSection + (event.deltaY > 0 ? 1 : -1);
        if (nextSection >= 0 && nextSection < sections.length) {
            scrollToSection(nextSection);
        }
    };

    const handleTouchStart = (event) => {
        touchStartY.current = event.touches[0].clientY;
    };

    const handleTouchMove = (event) => {
        event.preventDefault()
        touchEndY.current = event.touches[0].clientY;
    };

    const handleTouchEnd = () => {
        if (isScrolling.current || isFormActive) return;

        const distance = touchStartY.current - touchEndY.current;
        const threshold = 50; // Minimum distance to consider as a scroll gesture

        if (distance > threshold) {
            // Swipe up
            const nextSection = currentSection + 1;
            if (nextSection < sections.length) {
                scrollToSection(nextSection);
            }
        } else if (distance < -threshold) {
            // Swipe down
            const prevSection = currentSection - 1;
            if (prevSection >= 0) {
                scrollToSection(prevSection);
            }
        }
    };

    const handleNavigate = (index) => {
        if (!isFormActive) {
            scrollToSection(index);
        }
    };

    return (
        <>
            <Header sections={sections} onNavigate={handleNavigate} />
            <div
                className={styles['full-screen-container']}
                onWheel={handleScroll}
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onTouchEnd={handleTouchEnd}
            >
                {isFormActive ? (
                    <div className={`${styles['full-screen-section']} ${styles.active}`} style={{
                        zIndex: sections.length,
                        top: '50%',
                        left: '50%',
                        transformOrigin: 'center',
                        transform: 'translate(-50%, -50%)',
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}>
                        <FormSection onClose={() => toggleForm(false)} />
                    </div>
                ) : (
                    sections.map((section, index) => (
                        <div
                            id={section.id}
                            key={index}
                            className={`${styles['full-screen-section']} ${
                                index === currentSection ? styles.active : ''
                            }`}
                            style={{
                                zIndex: sections.length - index,
                                top: '50%',
                                left: '50%',
                                transformOrigin: 'center',
                                transform: `translate(-50%, -50%) translateY(${(index - currentSection) * 100}%)`,
                                position: 'absolute',
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            {React.createElement(section.component, {
                                onOpenFormSection: () => toggleForm(true),
                            })}
                        </div>
                    ))
                )}
            </div>
        </>
    );
};

export default FullScreenSections;